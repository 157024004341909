import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'react-emotion'
import Img from 'gatsby-image'
import { Avatar } from 'antd'
import { DiscussionEmbed } from 'disqus-react'

import 'katex/dist/katex.min.css'

import Layout from '../components/layout'

const MobileContainer = styled('div')`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }
`

const Title = styled.h2`
  z-index: 2;
  color: white;
  font-weight: bold;
  font-size: 36px;
  text-shadow: 1px 1px 1px #001529, -1px 1px 1px #001529, 1px -1px 1px #001529,
    -1px -1px 1px #001529, 0px 1px 1px #001529, 0px -1px 1px #001529, -1px 0px 1px #001529,
    1px 0px 1px #001529, 2px 2px 1px #001529, -2px 2px 1px #001529, 2px -2px 1px #001529,
    -2px -2px 1px #001529, 0px 2px 1px #001529, 0px -2px 1px #001529, -2px 0px 1px #001529,
    2px 0px 1px #001529, 1px 2px 1px #001529, -1px 2px 1px #001529, 1px -2px 1px #001529,
    -1px -2px 1px #001529, 2px 1px 1px #001529, -2px 1px 1px #001529, 2px -1px 1px #001529,
    -2px -1px 1px #001529;
`

const Post = ({ data, location, pageContext }) => {
  const post = data.markdownRemark
  const { title, author, date } = post.frontmatter
  const { html } = post
  const { prev, next } = pageContext

  const disqusShortname = 'cod-stats'
  const disqusConfig = {
    identifier: post.id,
    title: post.frontmatter.title,
  }

  return (
    <Layout location={location}>
      <div style={{ display: 'block', position: 'relative', height: '60vh', width: '100%' }}>
        <Img
          css={{ top: 0, left: 0, right: 0, bottom: 0 }}
          style={{ position: `absolute` }}
          fluid={post.frontmatter.cover.childImageSharp.fluid}
        />
        <MobileContainer
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              zIndex: 2,
            }}
          >
            <Avatar
              style={{
                marginRight: '12px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#40a9ff',
              }}
              size="large"
              src={post.frontmatter.avatar.childImageSharp.fixed.src}
              srcSet={post.frontmatter.avatar.childImageSharp.fixed.srcSet}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                backgroundColor: 'white',
                borderRadius: '48px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#40a9ff',
              }}
            >
              <span
                style={{
                  verticalAlign: 'bottom',
                  padding: '0px 12px',
                  height: '38px',
                  lineHeight: '38px',
                  fontWeight: 'bold',
                }}
              >
                By {author} on {date}
              </span>
            </div>
          </div>
          <Title>{title}</Title>
        </MobileContainer>
      </div>
      <MobileContainer styled={{ position: 'relative' }}>
        {/* eslint-disable-next-line react/no-danger */}
        <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: html }} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            marginBottom: '15px',
          }}
        >
          <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
            {prev && (
              <Link to={`/articles${prev.frontmatter.path}`}>
                <span>{`< Previous Post`}</span>
              </Link>
            )}
          </div>
          <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
            {next && (
              <Link to={`/articles${next.frontmatter.path}`}>
                <span>{`Next Post >`}</span>
              </Link>
            )}
          </div>
        </div>
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
      </MobileContainer>
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({
    prev: PropTypes.shape({}),
    next: PropTypes.shape({}),
  }).isRequired,
}

export default Post

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        date
        title
        author
        avatar {
          childImageSharp {
            fixed(width: 280, height: 280, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cover {
          childImageSharp {
            fluid(
              maxWidth: 2880
              quality: 90
              duotone: { highlight: "#40a9ff", shadow: "#001529", opacity: 40 }
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`
